 class Calendar {
	constructor(element) {
		 this.element = element;
		 return this;
	 }

	calendarCreation(data) {
		this.element.fullCalendar({
			height: 200,
			eventSources:[data],
			header: {
				left: 'title',
				center: '',
				right: 'prev,next,today,month'
			},
			firstDay: 1,
			monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
			monthNamesShort: ["Янв.", "Фев.", "Март.", "Апр.", "Май", "Июнь", "Июль", "Авг.", "Сент.", "Окт.", "Ноя.", "Дек."],
			dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
			dayNamesShort: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
			buttonText: {
				today: "Сегодня",
				month: "Месяц",
				week: "Неделя",
				day: "День"
			},
			timeFormat: 'H(:mm)',
			disableDragging: false,
			eventClick: function(calEvent,cell){
				window.open("/app"+calEvent.href);
			},
			eventRender: function(calEvent, cell) {
				$(cell).tooltip({title: this.title, html:true});
			}
	 });
	}

	create() {
		const proto = this;
		$.ajax({
			type: "post",
			url: 'calendar/',
			global: false,
			success: function (data) {
				proto.calendarCreation(data);
			},
			error: function(error) {
				proto.calendarCreation({});
				console.log('Data not found');
			},
		});
	}

 }

$.fn.calendar = function() {
	return new Calendar(this);
};

